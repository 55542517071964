@import "../../_variables.scss";
@import "../../_mixins.scss";

.careerBox {
  @include boxStyling;
  flex: 1 1 66%;
}

.title {
  margin-top: 60px;
  margin-left: 20px;
  color: #ffffff;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 40px;
  margin-bottom: 0px;
}
.subtitle {
  margin-left: 20px;
  color: #ffffff;
  max-width: 500px;
  text-align: left;
  margin-bottom: 40px;
  & a {
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }
}

.careersContainer {
  display: flex;
  margin-top: -80px;
  z-index: 1;
}

.careerBox,
.noOpenCareersBox {
  flex: 1 1 auto;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.11);
  margin: 50px;
  padding: 20px;
}
.careerBox p,
.careerBox ul {
  text-align: left;
}

.noOpenCareersBox {
  min-height: 300px;
  display: flex;
  margin-top: -80px;
  z-index: 1;
  font-size: 40px;
  color: $primary;
  font-weight: $semiBold;
}

.headingTwo {
  text-align: center;
  color: $primary;
}

.textStrong {
  color: $primary;
  font-weight: $semiBold;
}

.bullet {
  margin-left: 40px;
}

.text {
  padding-left: 20px;
}

.linkRow {
  display: flex;
  align-items: center;
}

.link,
.zipRecruiterWidget a {
  color: $primary;
  font-weight: $semiBold;
  display: flex;
  align-items: center;
  i {
    font-size: 35px;
    margin-right: $baselineHalf;
  }
  img {
    margin-right: $baselineHalf;
  }
}

.linkText {
  margin-right: $baselineHalf;
  font-weight: $semiBold;
}

.linkIcon {
  margin-left: $baseline;
  height: 30px;
}

// ZipRecruiter widget styling
.zipRecruiterWidget div[id="jobs_widget_container"] h3 {
  color: $primary;
  font-size: $fontXLarge;
  padding-left: 1rem;
}
.zipRecruiterWidget div[id="jobs_widget_container"] {
  background-color: $white;
}

.zipRecruiterWidget div[id="jobs_widget_container"] th,
.zipRecruiterWidget div[id="jobs_widget_container"] td {
  text-align: left;
  padding: 0.5rem 1rem;
}
.zipRecruiterWidget div[id="jobs_widget_container"] tbody tr:nth-child(odd) {
  background-color: $lightGray;
}
.zipRecruiterWidget div[id="jobs_widget_container"] tbody tr:nth-child(even) {
  background-color: $white;
}
.zipRecruiterWidget div[id="jobs_widget_container"] colgroup {
  display: block;
}
.zipRecruiterWidget div[id="jobs_widget_container"] table {
  border-collapse: collapse;
}

.zipRecruiterWidget div[id="jobs_widget_container"] {
  padding-bottom: 3rem;
}
.zipRecruiterWidget div[id="jobs_widget_container"] {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .careersContainer {
    @include flexColumn;
  }
  .linkRow {
    @include flexColumn;
  }
  .linkText {
    font-size: $fontXLarge;
  }
  .link {
    margin-bottom: $baseline;
  }
  .linkIcon {
    margin-left: 0;
  }
  .careerBox {
    margin: 3.25rem 2rem 3.25rem 2rem;
  }
  ul {
    padding-left: $baselineHalf;
  }
}
