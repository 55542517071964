body {
  background: #f7f8fb;

  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex: 1 1 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Lato", sans-serif;
  font-size: 26px;
  font-weight: 700;
}

h2 {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-weight: 700;
}

h3 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

div,
p,
span {
  font-size: 16px;
  font-weight: 400;
}

label {
  font-size: 14px;
  font-weight: 600;
}

.social-icon {
  color: rgba(0, 36, 105, 0.9);
  font-size: 40px;
}
