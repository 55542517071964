@import "../../_variables.scss";
@import "../../_mixins.scss";

.header {
  box-shadow: $standardShadow;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.logo {
  max-width: 250px;
  padding: 10px 0px 10px $baselineHalf;
}

.linkContainer {
  display: flex;
  margin-right: $baselineHalf;
}

.link {
  margin-right: $baselineHalf;
  font-weight: $semiBold;
  color: $primary;
  text-decoration: none;
  padding-bottom: 5px;
  &.active {
    border-bottom: $borderHalf;
  }
}

.menuIcon {
  display: none;
}

@media screen and (max-width: 600px) {
  .header {
    z-index: 3;
  }
  .menuIcon {
    margin-right: $baselineHalf;
    cursor: pointer;
    display: block;
    color: $primary;
    > i {
      font-size: 35px;
    }
  }
  .header .link {
    display: none;
  }
  .mobileLinkContainer .link {
    margin-top: $baseline;
    font-size: $fontXLarge;
    top: 0;
    display: block;
    margin-right: 0;
    text-align: center;
  }
  .mobileLinkContainer {
    @include flexColumn;
    background-color: $lightGray;
    position: absolute;
    left: 0px;
    top: 94px;
    width: 100%;
    align-items: center;
    padding-bottom: 30px;
  }
}
