@import "../../_mixins.scss";
@import "../../_variables.scss";

.serviceContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 50px;
  z-index: 1;
  margin-top: -50px;
}

.detailsContainer {
  @include flexColumn;
  justify-content: space-between;
}

.innerContainer {
  display: flex;
  margin-top: -80px;
  z-index: 1;
}

.serviceBox {
  @include boxStyling;
  flex: 1 1 66%;
}

.headingTwo {
  color: $primary;
  text-align: center;
}

.text {
  padding-left: 20px;
}

.textStrong {
  color: $primary;
  font-weight: 600;
}

.bullet {
  margin-left: 40px;
}

@media screen and (max-width: 600px) {
  .innerContainer {
    @include flexColumn;
  }
  .linkContainer {
    width: 100%;
  }
  .serviceBox {
    margin: 3.25rem 2rem 3.25rem 2rem;
  }
  ul {
    padding-left: $baselineHalf;
  }
}
