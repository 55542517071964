@import "../../_variables.scss";
@import "../../_mixins.scss";

.box {
  @include flexColumn;
  background: $white;
  padding: 30px $baselineHalf;
  margin: $baselineHalf;
  border-radius: $standardRadius;
  text-align: left;
  flex: 1 1 auto;
  box-shadow: 2px 2px 7px -2px rgba(33, 33, 33, 0.5);
  width: 300px;
  justify-content: space-between;
}

.title {
  margin-bottom: 0px;
  margin-top: 0px;
  color: $primary;
}

.learnMoreText {
  color: $primary;
  font-size: $fontSmall;
  font-weight: $semiBold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
  i {
    font-size: $fontSmall;
    margin-left: 5px;
  }
}

@media screen and (max-width: 600px) {
  .box {
    p {
      font-size: $fontRegular;
    }
  }
  .learnMoreText {
    font-size: $fontRegular;
  }
}
