// -------------------------------------------------------------
// Colors
// -------------------------------------------------------------
$white: rgb(255, 255, 255);
$offWhite: rgb(247, 248, 251);
$lightGray: rgb(248, 248, 248);
$lightestGray: rgb(253, 253, 253);
$primary: rgb(0, 36, 105);
$black: rgb(0, 0, 0);
$transparentBlack: rgba(0, 0, 0, 0.5);

// -------------------------------------------------------------
// Typography
// -------------------------------------------------------------
$light: 400;
$regular: 500;
$semiBold: 600;
$bold: 700;
$fontXSmall: 12px;
$fontSmall: 14px;
$fontRegular: 16px;
$fontLarge: 20px;
$fontXLarge: 22px;

// -------------------------------------------------------------
// Content
// -------------------------------------------------------------
$baseline: 2.5rem;
$baselineHalf: 1.25rem;
$standardShadow: 0px 1px 8px rgba(0, 0, 0, 0.11);
$standardRadius: 5px;
$borderStandard: 4px solid rgba(0, 36, 105, 0.9);
$borderHalf: 2px solid rgba(0, 36, 105, 0.9);
$borderTransparent: 4px solid transparent;
$mainGradient: linear-gradient(
  -20deg,
  rgba(0, 115, 54, 0.9) 0%,
  rgba(0, 36, 105, 0.9) 100%
);
