@import "../../_variables.scss";
@import "../../_mixins.scss";

.linkContainer {
  @include flexColumn;
  margin: 0 $baselineHalf;
  align-self: flex-start;
  min-width: 250px;
  flex: 1 1 33%;
}

.links {
  @include flexColumn;
  margin: 50px $baselineHalf;
  background: $white;
  border-radius: $standardRadius;
  box-shadow: $standardShadow;
  min-width: 250px;
  flex: 1 1 auto;
  align-self: flex-start;
}

.link {
  padding: $baselineHalf;
  color: $black;
  font-weight: $regular;
  border-bottom: 0.5px solid rgba(33, 33, 33, 0.15);
  text-decoration: none;
  &:not(:first-child) {
    border-right: $borderTransparent;
  }
  &:first-child {
    font-size: $fontLarge;
    font-weight: $regular;
    text-align: center;
  }
}

.active {
  &:not(:first-child) {
    border-right: $borderStandard;
    background: $lightGray;
    color: $primary;
  }
}

@media screen and (max-width: 600px) {
  .linkContainer {
    margin: auto;
    width: 100%;
  }
  .links {
    width: calc(100% - 60px);
    margin: 1.25rem auto 0 auto;
  }
}
