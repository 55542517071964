@import "../../_variables.scss";
@import "../../_mixins.scss";

.container {
  @include flexColumn;
  background: $offWhite;
  padding: 0px $baseline;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
}

.heading {
  font-size: 50px;
  margin: 0;
  color: $primary;
}

.text {
  font-size: $fontLarge;
}

.link {
  color: $primary;
  font-weight: $regular;
}
