@import "../../_variables.scss";
@import "../../_mixins.scss";

.innerContainer {
  @include flexColumn;
  margin: $baselineHalf;
  padding: $baselineHalf;
  text-align: left;
  z-index: 1;
  & .half {
    flex: 1 1 50%;
  }
}

.aboutRow {
  display: flex;
}

.headingAccent {
  display: block;
  height: 3px;
  width: 90px;
  background-color: $transparentBlack;
}

.headingThree {
  color: $primary;
  font-size: $fontXLarge;
  margin-bottom: 10px;
}

.pressRelease {
  @include flexColumn;
  max-width: 50%;
  border-left: $borderStandard;
  padding-left: 10px;
  margin-top: $baselineHalf;
  .headingFour {
    color: $primary;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .shortDesc {
    font-size: $fontSmall;
  }
  .link {
    font-size: $fontXSmall;
    text-decoration: underline;
    margin-top: 5px;
    color: $primary;
    font-weight: $regular;
  }
  .heading {
    color: $primary;
    font-size: $fontXLarge;
    text-align: left;
    margin-bottom: 5px;
  }
  .date {
    margin-bottom: 15px;
    font-style: italic;
    font-size: $fontXSmall;
    color: $primary;
  }
}

@media screen and (max-width: 600px) {
  .aboutRow {
    flex-direction: column;
  }
  .pressRelease {
    max-width: 100%;
    .headingFour {
      font-size: $fontXLarge;
    }
    .date {
      font-size: $fontRegular;
    }
    .shortDesc {
      font-size: $fontLarge;
    }
    .link {
      font-size: $fontRegular;
    }
  }
  .innerContainer {
    p {
      font-size: $fontLarge;
      margin-bottom: 0;
    }
  }
}
