@import "../../_variables.scss";
@import "../../_mixins.scss";

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $baselineHalf;
}

.mainButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-top: $baseline;
  border-radius: 29px;
  font-family: "Lato", sans-serif;
  color: $white;
  border: none;
  background: $primary;
  box-shadow: 4px 4px 6px 2px rgba(33, 33, 33, 0.2);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: $light;
  letter-spacing: 1.8px;
  font-size: $fontSmall;
  text-decoration: none;
  padding: 0px $baseline;
  &:focus {
    outline: none;
  }
}

/* Ripple effect */
.mainButton {
  background-position: center;
  transition: background 0.8s;
}

.mainButton:hover {
  background: rgba(0, 40, 130, 1)
    radial-gradient(circle, transparent 1%, rgba(0, 40, 130, 1) 1%)
    center/15000%;
}

.mainButton:active {
  background-color: rgba(0, 36, 105, 0.5);
  background-size: 100%;
  transition: background 0s;
  box-shadow: 4px 4px 6px -2px rgba(33, 33, 33, 0.5);
}

@media screen and (max-width: 600px) {
  .mainButton {
    text-align: center;
  }
}
