@import "../../_variables.scss";

.hero {
  position: relative;
  display: flex;
  justify-content: flex-end;
  min-height: 630px;
}

.heroOverlay {
  background: $mainGradient;
  mix-blend-mode: multiply;
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.heroImg {
  max-width: 620px;
  opacity: 0.75;
  position: absolute;
  top: 20%;
}

.heroText {
  z-index: 2;
  display: flex;
  flex: 1 1 auto;
  text-align: left;
  flex-direction: column;
  margin: auto;
  text-align: center;
}

.heroHeadingOne {
  font-size: 35px;
  font-weight: bold;
  max-width: 750px;
  margin: 20px auto;
  margin-bottom: 30px;
}

.heroHeadingThree {
  font-size: 18px;
  font-weight: 500;
  max-width: 500px;
  margin: 10px auto;
}

.heroHeadingThree,
.heroHeadingOne {
  color: rgba(255, 255, 255, 0.85);
}

.highlights {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: 80px auto;
}

.highlightSubtext,
.highlightText {
  text-align: center;
}

.icons {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
}

.iconContainer {
  display: flex;
  flex: 1 1 33%;
  margin: 0px 10px;
  flex-direction: column;
  align-items: center;
}

.iconContainer:hover {
  & .iconCircle {
    background-color: #fdfdfd;
    color: rgba(0, 36, 105, 0.9);
    transition: all 0.3s linear;
    cursor: default;
  }
}

.clients,
.chooseUsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px auto;
}

.clientsHeading,
.chooseUsHeading,
.highlightsHeading {
  font-size: 36px;
  margin-top: 0px;
  text-align: center;
  margin-bottom: 15px;
  color: rgba(0, 36, 105, 0.9);
}

.highlightsHeading {
  margin-top: 0px;
  margin-bottom: 60px;
}

.clientsDescription,
.chooseUsDescr {
  max-width: 70%;
  margin-bottom: 50px;
}

.chooseUsDescr {
  margin: 0px;
  margin-bottom: 20px;
  text-align: left;
}

.certified {
  background: #fdfdfd;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding: 60px 0px;
}

.featureIcon {
  font-size: 40px;
  height: 60px;
}

.highlightText {
  margin-bottom: 0px;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .icons {
    flex-direction: column;
  }
  .iconContainer {
    margin: 20px auto;
    width: 50%;
  }
  .featureIcon {
    height: 100px;
  }
  .certified {
    flex-direction: column;
  }
  .certified * {
    margin-top: 20px;
  }
  .hero {
    min-height: unset;
    height: 700px;
  }
  .heroText {
    align-items: center;
    padding: 0 $baselineHalf;
    h1 {
      font-size: 40px;
    }
    h3 {
      font-size: $fontLarge;
    }
  }
  .heroImg {
    max-width: 100%;
    opacity: 0.5;
  }
  .highlightText {
    font-size: $fontXLarge;
  }

  .highlightSubtext,
  .chooseUsDescr {
    font-size: $fontLarge;
  }
}

.imgContainer {
  margin: 2rem 1rem;
}
