@import "./_variables.scss";

@mixin flexColumn {
  display: flex;
  flex-direction: column;
}

@mixin boxStyling {
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.11);
  margin: 3.125rem;
  padding: 1.25rem;
}

@mixin h1 {
  color: $white;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 2.5rem;
  margin-bottom: 0;
}
