@import "../../_variables.scss";
@import "../../_mixins.scss";

.container {
  @include flexColumn;
  background-color: $offWhite;
  margin: $baseline;
  align-items: flex-start;
  justify-content: space-between;
  p {
    margin-top: $baselineHalf;
    text-align: left;
    line-height: 1.4;
  }
}

.textStrong {
  font-weight: $semiBold;
  + p {
    display: inline;
  }
}

.quoteContainer {
  margin-left: $baselineHalf;
  .quoteText {
    border-left: $borderStandard;
    padding-left: $baselineHalf;
  }
  .quoteByLine {
    margin-left: $baselineHalf;
    font-size: $fontSmall;
    font-style: italic;
  }
}

.preContent {
  font-weight: $semiBold;
  font-size: $fontSmall;
  font-style: italic;
}

.headingAccentLg {
  background-color: $transparentBlack;
  display: block;
  height: 3px;
  width: 180px;
}

.heading {
  color: $primary;
  font-size: $fontXLarge;
  text-align: left;
  margin-bottom: 5px;
}

.date {
  font-size: $fontXSmall;
  color: $primary;
  font-style: italic;
  margin-bottom: 15px;
}

.info {
  font-weight: $semiBold;
  font-size: $fontSmall;
  font-style: italic;
}

.link {
  color: $primary;
  font-weight: $regular;
}
