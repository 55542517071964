@import "../../_variables.scss";
@import "../../_mixins.scss";

:global {
  #hubspot-form {
    margin: $baseline auto;
    min-width: 500px;
  }

  #hubspot-form label {
    color: $primary;
  }
}

.form {
  display: flex;
  width: 90%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding-left: 22px;
}

@media screen and (max-width: 600px) {
  .form {
    width: 85%;
  }

  :global {
    #hubspot-form {
      width: 100%;
      min-width: unset;
    }
  }
}
