@import "../../_variables.scss";
@import "../../_mixins.scss";

.footer {
  @include flexColumn;
  background: $lightestGray;
  align-items: center;
  padding: 50px 0px;
}

.text {
  color: $primary;
  font-weight: $regular;
  margin-top: 0;
}

@media screen and (max-width: 600px) {
  .text {
    font-size: $fontLarge;
    margin-bottom: $baseline;
    i {
      font-size: 60px;
    }
  }
}
