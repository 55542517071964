@import "../../_mixins.scss";
@import "../../_variables.scss";

.container {
  @include flexColumn;
  background: $mainGradient;
  align-items: flex-start;
  flex: 1 1 auto;
  position: relative;
  min-height: 275px;
  overflow: hidden;
}

.innerContainer {
  margin-bottom: 50px;
  position: relative;
  z-index: 2;
}

.title {
  @include h1;
  margin-top: 60px;
  margin-left: $baselineHalf;
}

.subtitle {
  color: $white;
  margin-left: $baselineHalf;
  max-width: 500px;
  margin-bottom: $baseline;
  text-align: left;
}

.heroImg {
  position: absolute;
  mix-blend-mode: hard-light;
  height: 300px;
  right: 60px;
  top: 50px;
  opacity: 0.5;
}

@media screen and (max-width: 600px) {
  .innerContainer {
    padding: 0 $baselineHalf;
  }
  .title {
    margin-left: 0;
  }
  .subtitle {
    font-size: $fontLarge;
    font-weight: $semiBold;
    margin-left: 0;
  }
}
